<template>
  <div>
    <v-btn bottom
           color="#95C11F"
           dark
           fab
           fixed
           right
           @click="dialog = !dialog">
      <v-icon>add</v-icon>
    </v-btn>
    <v-dialog
        v-model="dialog"
        width="800px">
      <v-card>
        <v-card-title class=" darken-2">
          Create Company
        </v-card-title>
        <v-container>
          <v-row class="mx-2">
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-account-card-details-outline"
                            placeholder="Company Name" v-model="company.name" :rules="['Required']"/>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="formItem">
              <v-text-field
                  label="C.I.F."
                  name="cif"
                  prepend-icon="mdi-account-card-details-outline"
                  type="text"
                  v-model="company.cif"
                  :rules="['Required']"/>
            </v-col>
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-domain" placeholder="Address" v-model="company.address" :rules="['Required']"/>
            </v-col>
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-phone" placeholder="Phone" v-model="company.phone" :rules="['Required']"/>
            </v-col>
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-mail" placeholder="Email" v-model="company.mail" :rules="['Required']"/>
            </v-col>
            <v-col cols="6">
              <v-select
                  prepend-icon="mdi-flag"
                  :items="countries"
                  label="Country"
                  v-model="company.country"
                  :rules="['Required']"/>
            </v-col>
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-desktop-mac" placeholder="Web Page" v-model="company.page"/>
            </v-col>
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-mail" placeholder="Contact Email" v-model="company.contactMail" :rules="['Required']"/>
            </v-col>
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-phone" placeholder="Contact Phone" v-model="company.contactPhone" :rules="['Required']"/>
            </v-col>
            <v-col cols="6">
              <v-select
                  prepend-icon="mdi-server"
                  :items="sectors"
                  label="Sector"
                  v-model="company.sector" :rules="['Required']"/>
            </v-col>
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-group" placeholder="Number of Workers" v-model="company.nWorker" :rules="['Required']"/>
            </v-col>
            <v-col cols="12">
              <v-textarea
                  prepend-icon="mdi-note"
                  name="input-7-1"
                  label="Additional Info"
                  hint="Hint text"
                  v-model="company.info"/>
            </v-col>
          </v-row>
          <empty-form :alert="alert"/>
        </v-container>
        <v-card-actions>
          <v-btn class="modalBT" v-on:click="createCompany" small color="#213B87">Save</v-btn>
          <v-btn class="modalBT" @click="dialog = false" small color="#9D130B">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EmptyForm from "../Alerts/emptyForm";
export default {
  name: "createCompanyForm",
  components: {EmptyForm},
  props: ['refreshData'],
  data() {
    return {
      dialog: false,
      alert:false,
      company:[],
      countries: [],
      sectors: []
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    createCompany() {
      if(this.company.name===undefined || this.company.cif===undefined ||this.company.address===undefined ||this.company.mail===undefined ||this.company.phone===undefined){
        this.alert=true;
      }else{
        let url = '';
        if (localStorage.rol === '1')
          url = this.base_url + '/admin/newcompany';
        else
          url = this.base_url + '/partner/newcompany';
        let formData = new FormData();
        formData.set('name', this.company.name);
        formData.set('cif', this.company.cif);
        formData.set('address', this.company.address);
        formData.set('postalCode', this.company.postalCode);
        formData.set('city', this.company.city);
        formData.set('phone', this.company.phone);
        formData.set('email', this.company.mail);
        formData.set('cid', this.company.country);
        formData.set('webURL', this.company.page);
        formData.set('contactEmail', this.company.contactMail);
        formData.set('contactPhone', this.company.contactPhone);
        formData.set('sid', this.company.sector);
        formData.set('nWorkers', this.company.nWorker);
        formData.set('info', this.company.info);
        formData.set('token', localStorage.token);
        this.axios.post(url, formData).then(() => {
          this.dialog = false;
          this.company = [];
          this.refreshData();
        });
      }
    },
    getData() {
      let url1 = this.base_url + '/home/sectors';
      let url2 = '';
      if (localStorage.rol === '2')
        url2 = this.base_url + "/partner/countriesList/" + localStorage.token;
      else
        url2 = this.base_url + '/home/countries';
      this.axios.get(url1).then(response => {
        this.sectors = response.data.sectors;
      })
      this.axios.get(url2).then(response => {
        this.countries = response.data.countries;
        if (localStorage.rol === '2')
          this.country = response.data.countries[0].value;
      })
    }
  }
}
</script>

<style scoped>

</style>