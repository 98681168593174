<template>
    <v-col cols="12" :md="md">
        <v-card-title style="color:#213B87">
            {{label}}
        </v-card-title>
        <v-card-subtitle>
            {{text}}
        </v-card-subtitle>
    </v-col>
</template>

<script>
    export default {
        name: "offerInput",
        props: ['md', 'label', 'text']
    }
</script>

<style scoped>

</style>