import { render, staticRenderFns } from "./infoCheck.vue?vue&type=template&id=14079b3a&scoped=true&"
import script from "./infoCheck.vue?vue&type=script&lang=js&"
export * from "./infoCheck.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14079b3a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardSubtitle,VCheckbox,VCol})
