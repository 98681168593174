<template>
  <v-app>
    <div v-if="$route.path.includes('/dashboard')">
      <v-navigation-drawer v-model="drawer"
                           :clipped="$vuetify.breakpoint.lgAndUp"
                           app>
        <v-list dense>
          <v-list-item-group color="primary">
            <v-list-item
                color="rgb(33, 59, 135)"
                v-for="(item, i) in items"
                :key="i"
                :to="item.link">
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <p class="navfooterp"><strong>v1.0</strong> - Powered by <a href="https://erovet.eu/"
                                                                    rel="noopener nofollow" target="_blank">Erovet+</a>
        </p>
      </v-navigation-drawer>
      <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp"
                 app
                 color="#FFF"
                 dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" style="color:#000 !important;"/>

        <v-toolbar-title style="width: 300px"
                         class="ml-0 pl-4">
                    <span>
                        <router-link to="/">
                           <img src="./assets/images/WEB-TOP-EROVET.png" :width="isMobile() ? 110 : 300" alt="logoerovet"/>
                        </router-link>
                    </span>
        </v-toolbar-title>
        <v-spacer/>
        <v-btn v-if="$route.path != '/'" tile outlined class="headButton">
          <router-link to="/">
            Offers
            <v-icon right>mdi-file-multiple</v-icon>
          </router-link>
        </v-btn>
        <v-btn tile outlined class="headButton" v-on:click="signOut">
          Logout
          <v-icon right>mdi-exit-to-app</v-icon>
        </v-btn>
      </v-app-bar>
      <v-main>
        <v-container class="fill-height"
                     fluid>
          <RouterView/>
        </v-container>
      </v-main>
    </div>
    <div
        v-else-if="!$route.path.includes('/dashboard') && !$route.path.includes('/register') &&!$route.path.includes('/login')">
      <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp"
                 app
                 color="#FFF"
                 dark>
        <v-toolbar-title style="width: 300px"
                         class="ml-0 pl-4">
                    <span >
                        <router-link to="/">
                           <img src="./assets/images/WEB-TOP-EROVET.png" :width="isMobile() ? 110 : 300" alt="logoerovet"/>
                        </router-link>
                    </span>
        </v-toolbar-title>
        <v-spacer/>
        <v-btn v-if="$route.path != '/'" tile outlined class="headButton">
          <router-link to="/">
            Offers
            <v-icon right>mdi-file-multiple</v-icon>
          </router-link>
        </v-btn>
        <v-btn v-if="!$route.path.includes('/partners')" tile outlined class="headButton">
          <a href="https://erovet.eu/our-community/#partner-iet" rel="nofollow noopener" target="_blank">
            Partners
            <v-icon right> mdi-account-multiple</v-icon>
          </a>
        </v-btn>
        <v-btn v-if="!$route.path.includes('/login') && !$route.path.includes('/register')" tile outlined
               color="success" class="headButton" v-on:click="goPanel">
          UserArea
          <v-icon right>mdi-account-key</v-icon>
        </v-btn>
      </v-app-bar>
      <v-content>
        <v-container class="fill-height" fluid>
          <RouterView/>
        </v-container>
      </v-content>
    </div>
    <RouterView v-else/>
  </v-app>
</template>
<script>
export default {
  props: {
    source: String,
  },
  data: () => ({
    drawer: null,
    logged: false,
    items: [],
    adminItems: [
      {icon: 'work', text: 'Home', link: '/dashboard/home'},
      {icon: 'group', text: 'Partners', link: '/dashboard/partners'},
      {icon: 'domain', text: 'Companies', link: '/dashboard/companies'},
      {icon: 'file_copy', text: 'Offers', link: '/dashboard/offers'},
      {icon: 'settings', text: 'Settings', link: '/dashboard/settings'}
    ],
    partnerItems: [
      {icon: 'work', text: 'Home', link: '/dashboard/home'},
      {icon: 'domain', text: 'My Companies', link: '/dashboard/companies'},
      {icon: 'file_copy', text: 'Offers', link: '/dashboard/offers'},
    ],
    companyItems: [
      {icon: 'work', text: 'Home', link: '/dashboard/home'},
      {icon: 'domain', text: 'My Partner', link: '/dashboard/partner/1'},
      {icon: 'file_copy', text: 'Offers', link: '/dashboard/offers'},
      {icon: 'domain', text: 'Support', link: '/dashboard/support'},
    ],
    studentItems: [
      {icon: 'file_copy', text: 'My Offers', link: '/dashboard/home'},
      {icon: 'domain', text: 'Support', link: '/dashboard/support'},
    ]
  }),
  updated() {
    this.checkSession()
  },
  methods: {
    checkSession() {
      this.logged = localStorage.token != null;
      if (!this.logged) {
          if(this.$route.params.token === undefined && this.$route.name !== 'Login' && this.$route.name !== 'Forgot' && this.$route.name !== 'Register') this.$router.push('/');
      } else {
        switch (localStorage.rol) {
          case '1':
            this.items = this.adminItems;
            break;
          case '2':
            this.items = this.partnerItems;
            break;
          case '3':
            this.items = this.companyItems;
            break;
          case '4':
            this.items = this.studentItems;
            break;
        }
      }
    },
    goPanel() {
      this.logged = localStorage.token != null;
      if (!this.logged) this.$router.push('/login');
      else this.$router.push('/dashboard/home');
    },
    signOut() {
      localStorage.clear();
      this.$router.push('/');
      location.reload();
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
  }
}
</script>
<style>
.itemMenu {
  text-decoration: none !important;
  color: rgb(33, 59, 135) !important;
}

.headButton.v-btn {
  border-style: none;
}

.headButton .v-btn__content {
  color: rgb(33, 59, 135) !important;
  font-size: 12pt;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: normal;
}

.headButton .v-btn__content a {
  color: rgb(33, 59, 135) !important;
  font-size: 12pt;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: normal;
}

.navfooterp {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  font-size: 9pt;
  color: gray;
}

.navfooterp a {
  color: darkgray;
  font-weight: bold;
  text-decoration: none;
}

.navfooterp a:hover {
  color: #000;
}
</style>
