<template>
    <admin-view v-if="rol==='1'"/>
    <partner-view v-else-if="rol==='2'"/>
    <company-view v-else-if="rol==='3'"/>
    <student-view v-else-if="rol==='4'"/>
</template>
<script>
    import adminView from "../../components/Dashboard/DashHome/adminView";
    import partnerView from "../../components/Dashboard/DashHome/partnerView";
    import companyView from "../../components/Dashboard/DashHome/companyView";
    import studentView from "../../components/Dashboard/DashHome/studentView";

    export default {
        name: 'Home',
        components: {adminView, partnerView, companyView, studentView},
        data() {
            return {
                rol: localStorage.rol
            }
        }
    }
</script>
