<template>
    <v-card class="col-md-12" :loading="!loaded">
        <v-card-title>
            {{partner.name}}
            <a v-if="partner.fbURL != ''" :href="partner.fbURL" rel="nofollow" target="_blank">
                <v-icon color="blue darken-2" class="headRRSS">mdi-facebook</v-icon>
            </a>
            <a v-if="partner.twURL != ''" :href="partner.twURL" rel="nofollow" target="_blank">
                <v-icon color="blue" class="headRRSS">mdi-twitter</v-icon>
            </a>
            <a v-if="partner.inURL != ''" :href="partner.inURL" rel="nofollow" target="_blank">
                <v-icon color="gray darken-2" class="headRRSS">mdi-linkedin</v-icon>
            </a>
        </v-card-title>
        <v-form v-model="valid" v-if="loaded">
            <v-container>
                <v-row>
                    <info-input md="4" label="Address" :text="partner.address"/>
                    <info-input md="4" label="Phone" :text="partner.phone"/>
                    <info-input md="2" label="Country" :text="partner.country"/>
                    <info-input md="2" label="City" :text="partner.city"/>
                    <info-input md="6" label="Web Page" :text="partner.webPage"/>
                    <info-input md="6" label="Email" :text="partner.email"/>
                </v-row>
            </v-container>
        </v-form>
        <v-card-title v-if="loaded">Contact Persons</v-card-title>
        <v-simple-table dense v-if="loaded">
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">Name</th>
                    <th class="text-left">Email</th>
                    <th class="text-left">Phone</th>
                    <th class="text-left">Position</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in persons" :key="item.cid">
                    <td>{{ item.name }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.phone }}</td>
                    <td>{{ item.position }}</td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-card-title v-if="loaded && rol === '1'">Companies</v-card-title>
        <v-simple-table dense v-if="loaded && rol === '1'">
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">Name</th>
                    <th class="text-left">Address</th>
                    <th class="text-left">Sector</th>
                    <th class="text-left">Status</th>
                    <th class="text-left">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in companies" :key="item.name">
                    <td>{{ item.name }}</td>
                    <td>{{ item.address }}</td>
                    <td>{{ item.sector }}</td>
                    <td>{{ item.status }}</td>
                    <td>
                        <router-link :to="'/dashboard/company/'+item.cid">
                            <v-icon color="#000 !important"
                                    small>
                                mdi-eye
                            </v-icon>
                        </router-link>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>

<script>
    import infoInput from "../../components/Dashboard/general/infoInput";

    export default {
        name: "Offer",
        components: {infoInput},
        data() {
            return {
                sector: 'IT',
                loaded: false,
                rol: localStorage.rol,
                partner: [],
                persons: [],
                companies: [],

            }
        },
        mounted() {
            this.refreshData();
        },
        methods: {
            refreshData() {
                this.loaded = false;
                let url = '';
                if (localStorage.rol === '1')
                    url = this.base_url + '/admin/partner/'+ this.$route.params.id+'/' + localStorage.token;
                else
                    url = this.base_url + '/company/partner/' + localStorage.token;
                this.axios.get(url).then(response => {
                    this.partner = response.data.partner;
                    this.companies = response.data.companies;
                    this.persons = response.data.contactPersons;
                    this.loaded = true;
                });
            }
        }
    }
</script>

<style scoped>
    .headRRSS {
        margin-left: 0.5em;
    }
</style>