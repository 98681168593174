<template>
  <div>
    <v-btn bottom
           color="#95C11F"
           dark
           fab
           fixed
           right
           @click="dialog = !dialog">
      <v-icon>add</v-icon>
    </v-btn>
    <v-dialog
        v-model="dialog"
        width="800px">
      <v-card>
        <v-card-title class=" darken-2">
          Create Partner
        </v-card-title>
        <v-container>
          <v-row class="mx-2">
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-account-card-details-outline"
                            placeholder="Partner Name" v-model="partner.name" :rules="['Required']"/>
            </v-col>
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-domain" placeholder="Address" v-model="partner.address" :rules="['Required']"/>
            </v-col>
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-domain" placeholder="Postalcode" v-model="partner.postalCode" :rules="['Required']"/>
            </v-col>
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-domain" placeholder="City" v-model="partner.city" :rules="['Required']"/>
            </v-col>
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-phone" placeholder="Phone" v-model="partner.phone" :rules="['Required']"/>
            </v-col>
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-mail" placeholder="Email" v-model="partner.email" :rules="['Required']"/>
            </v-col>
            <v-col cols="6">
              <v-select
                  prepend-icon="mdi-flag"
                  :items="countries"
                  label="Country"
                  :rules="['Required']"
                  v-model="partner.country"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-desktop-mac" placeholder="Web Page" v-model="partner.webURL"/>
            </v-col>
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-facebook" placeholder="Facebook url" v-model="partner.fbURL"/>
            </v-col>
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-twitter" placeholder="Twitter url" v-model="partner.twURL"/>
            </v-col>
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-linkedin" placeholder="Linkedin url" v-model="partner.inURL"/>
            </v-col>
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-worker" placeholder="Contact Name" v-model="partner.contactName"/>
            </v-col>
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-phone" placeholder="Contact Phone" v-model="partner.contactPhone"/>
            </v-col>
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-mail" placeholder="Contact Email" v-model="partner.contactEmail"/>
            </v-col>
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-server" placeholder="Contact Position" v-model="partner.contactPosition"/>
            </v-col>
          </v-row>
          <empty-form :alert="alert"/>
        </v-container>
        <v-card-actions>
          <v-btn class="modalBT" v-on:click="createPartner" small color="#213B87">Save</v-btn>
          <v-btn class="modalBT" @click="dialog=false" small color="#9D130B">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import EmptyForm from "../Alerts/emptyForm";
export default {
  name: "createPartnerForm",
  components: {EmptyForm},
  props: ['refreshData'],
  data() {
    return {
      dialog: false,
      alert:false,
      partner: [],
      countries: [],
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    createPartner() {
      if (this.partner.name === undefined || this.partner.address === undefined || this.partner.postalCode === undefined || this.partner.city === undefined || this.partner.phone === undefined || this.partner.email === undefined || this.partner.country === undefined) {
        this.alert = true;
      } else {
        let url = this.base_url + '/admin/newpartner';
        let formData = new FormData();
        formData.set('name', this.partner.name);
        formData.set('address', this.partner.address);
        formData.set('postalCode', this.partner.postalCode);
        formData.set('city', this.partner.city);
        formData.set('phone', this.partner.phone);
        formData.set('email', this.partner.email);
        formData.set('cid', this.partner.country);
        formData.set('webURL', this.partner.webURL);
        formData.set('fbURL', this.partner.fbURL);
        formData.set('inURL', this.partner.inURL);
        formData.set('twURL', this.partner.twURL);
        formData.set('contactName', this.partner.contactName);
        formData.set('contactPosition', this.partner.contactPosition);
        formData.set('contactEmail', this.partner.contactEmail);
        formData.set('contactPhone', this.partner.contactPhone);
        formData.set('token', localStorage.token);
        this.axios.post(url, formData).then(() => {
          this.dialog = false;
          this.partner =[];
          this.refreshData();
        });
      }
    },
    getData() {
      let url = this.base_url + '/home/countries';
      this.axios.get(url).then(response => {
        this.countries = response.data.countries;
      })
    }
  }
}
</script>

<style scoped>

</style>