<template>
  <v-alert
      v-if="alert==true"
      border="right"
      colored-border
      type="error"
      elevation="2">
    Please fill in all the required fields.
  </v-alert>
</template>

<script>
export default {
name: "emptyForm",
  props:['alert']
}
</script>

<style scoped>

</style>