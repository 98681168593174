<template>
    <v-lazy v-model="isActive"
            :options="{threshold: .5 }"
            min-height="200"
            class="col-md-12"
            transition="fade-transition">
        <v-card class="col-md-12 offer" :loading="!loaded">
            <v-card-title class="titleOffer">Offer Detail - {{offer.date_add}}</v-card-title>
            <v-form v-model="valid">
                <v-container>
                    <v-row>
                        <offer-input md="12" label="" :text="offer.shortDesc"/>
                        <offer-input md="4" label="position" :text="offer.position"/>
                        <offer-input md="4" label="Sector" :text="offer.sector"/>
                        <offer-input md="2" label="City" :text="offer.city"/>
                        <offer-input md="2" label="Country" :text="offer.country"/>
                        <offer-input md="4" label="Company" :text="offer.company"/>
                        <offer-input md="4" label="Salary" :text="offer.salary"/>
                        <v-col cols="12" md="2">
                            <v-checkbox
                                    value="1"
                                    :label="`Fulltime`"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-checkbox
                                    value="false"
                                    label="Midtime"
                            ></v-checkbox>
                        </v-col>
                        <offer-input md="6" label="Offer Requeriments" :text="offer.requeriments"/>
                        <offer-input md="6" label="Qualification" :text="offer.qualification"/>
                        <offer-input md="6" label="Workplace Conditions" :text="offer.conditions"/>
                        <offer-input md="6" label="Rights" :text="offer.rights"/>
                        <offer-input md="12" label="Long Description" :text="offer.longDesc"/>
                    </v-row>
                </v-container>
            </v-form>
            <v-card-actions v-if="rol=='4'">
                <v-btn class="modalBT" small color="#213B87" v-if="applied==false" v-on:click="setAppliedOffer">Apply</v-btn>
                <v-btn class="modalBT" small color="#213B87" v-else>Applied</v-btn>
                <v-btn class="modalBT" v-on:click="closeOffer" small color="#9D130B">Cancel</v-btn>
            </v-card-actions>
            <v-card-actions v-else>
                <v-btn class="modalBT" v-on:click="closeOffer" small color="#9D130B">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-lazy>
</template>

<script>
    import offerInput from "../components/Offer/offerInput";

    export default {
        name: "Offer",
        components: {offerInput},
        data: () => ({
            isActive: false,
            sector: 'Social Services',
            offer: [],
            valid: true,
            loaded: false,
            rol: localStorage.rol,
            applied:false
        }),
        mounted() {
            this.getOffer();
        },
        methods: {
            getOffer() {
                this.loaded = false;
                let url = this.base_url + '/home/offer/' + this.$route.params.id;
                this.axios.get(url).then(response => {
                    this.offer = response.data.offer;
                    this.loaded = true;
                    if (this.rol == '4')
                        this.setVisitOffer()
                })
            },
            setVisitOffer() {
                let url = this.base_url + '/student/visitoffer';
                let formData = new FormData();
                formData.set('offerID', this.$route.params.id);
                formData.set('views', this.offer.views);
                formData.set('token', localStorage.token);
                this.axios.post(url, formData);
            },
            setAppliedOffer() {
                let url = this.base_url + '/student/applyoffer';
                let formData = new FormData();
                formData.set('offerID', this.$route.params.id);
                formData.set('token', localStorage.token);
                this.axios.post(url, formData).then(() => {
                    this.applied = true;
                });
            },
            closeOffer() {
                this.$router.push('/');
            }
        }
    }
</script>

<style>
    .titleOffer {
        font-family: 'Lato', sans-serif;
        text-transform: uppercase;
        color: #213B87;
        font-weight: bold;
        border-bottom: .1em solid #b2b2b2;
        letter-spacing: 3px;
    }


</style>
