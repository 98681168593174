<template>
    <v-card class="col-md-12">
        <modal name="updateFinishedStatus" transition="pop-out" :height="140">
            <v-card class="mx-auto" style="margin: 0 auto;">
                <v-list-item three-line>
                    <v-list-item-content>
                        <v-list-item-title class="headline mb-1">Do you want to {{operation}}
                            {{itemSelected.position}}?
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-card-actions>
                    <v-btn v-if="operation=='accept'" text color="rgb(33, 59, 135) !important"
                           style="font-size: 12pt !important" v-on:click="acceptOffer(itemSelected)">Accept
                    </v-btn>
                    <v-btn v-else text color="rgb(33, 59, 135) !important" style="font-size: 12pt !important"
                           v-on:click="rejectOffer(itemSelected)">Accept
                    </v-btn>
                    <v-btn text color="rgb(33, 59, 135) !important" style="font-size: 12pt !important"
                           @click="$modal.hide('updateFinishedStatus')">Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </modal>
        <v-card-title>
            Offers
            <v-icon style="font-size: 12pt !important;color:black !important;margin-left: 0.25em;" v-on:click="getData">
                mdi-reload
            </v-icon>
            <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
                :headers="headers"
                :items="offers"
                :search="search"
                :loading="!loaded"
                loading-text="Loading... Please wait"
                no-data-text="No offers available">
            <template v-slot:item.status="{item}">
                <tr>
                    <td v-if="item.status==='pending'" style="color:#E8AD00; text-transform:uppercase;">
                        {{item.status}}
                    </td>
                    <td v-else-if="item.status==='accepted'" style="color:#217A2B; text-transform:uppercase;">
                        {{item.status}}
                    </td>
                    <td v-else-if="item.status==='rejected'" style="color:#9D130B; text-transform:uppercase;">
                        {{item.status}}
                    </td>
                  <td v-else-if="item.status==='closed'" style="color:gray; text-transform:uppercase;">
                    {{item.status}}
                  </td>
                </tr>
            </template>
            <template v-slot:item.action="{ item }">
                <v-icon v-if="item.status !='rejected' && item.status != 'accepted' && (rol==='1' || rol==='2')"
                        color="#000 !important"
                        small
                        @click="showApproveDialog(item)">
                    mdi-check
                </v-icon>
                <v-icon v-if="item.status !='rejected' && item.status != 'accepted' && (rol==='1' || rol==='2')"
                        color="#000 !important"
                        small
                        @click="showRejectDialog(item)">
                    mdi-close
                </v-icon>
                <router-link :to="'/dashboard/offer/'+item.oid">
                    <v-icon
                            color="#000 !important"
                            small>
                        mdi-eye
                    </v-icon>
                </router-link>
            </template>
        </v-data-table>
        <create-offer-form :refresh-data="getData"/>
    </v-card>
</template>

<script>
    import createOfferForm from "../../components/Dashboard/Offers/createOfferForm";

    export default {
        name: "Offers",
        components: {createOfferForm},
        data() {
            return {
                rol: localStorage.rol,
                loaded: false,
                dialog: false,
                search: '',
                i: '',
                headers: [
                    {
                        text: 'Position',
                        align: 'start',
                        sortable: false,
                        value: 'position',
                    },
                    {text: 'Sector', value: 'sector'},
                    {text: 'Company', value: 'company'},
                    {text: 'Country', value: 'country'},
                    {text: 'Status', value: 'status'},
                    {text: 'Applies', value: 'applies'},
                    {text: 'Actions', value: 'action', sortable: false},
                ],
                countries: [],
                itemSelected: '',
                operation: ''
            }
        },
        mounted() {
            this.getData();
        },
        methods: {

            rejectOffer(item) {
                let url = '';
                if (localStorage.rol === '1')
                    url = this.base_url + '/admin/updateOffer';
                else if (localStorage.rol === '2')
                    url = this.base_url + '/partner/updateOffer';
                let formData = new FormData();
                formData.set('oid', item.oid);
                formData.set('status', 'rejected');
                formData.set('token', localStorage.token);
                this.axios.post(url, formData).then(() => {
                    this.getData();
                    this.$modal.hide('updateFinishedStatus');
                })
            },
            acceptOffer(item) {
                let url = '';
                if (localStorage.rol === '1')
                    url = this.base_url + '/admin/updateOffer';
                else if (localStorage.rol === '2')
                    url = this.base_url + '/partner/updateOffer';
                let formData = new FormData();
                formData.set('oid', item.oid);
                formData.set('status', 'accepted');
                formData.set('token', localStorage.token);
                this.axios.post(url, formData).then(() => {
                    this.getData();
                    this.$modal.hide('updateFinishedStatus');
                })
            },
            showApproveDialog(item) {
                this.itemSelected = item
                this.operation = 'accept'
                this.$modal.show('updateFinishedStatus')
            },
            showRejectDialog(item) {
                this.itemSelected = item
                this.operation = 'decline'
                this.$modal.show('updateFinishedStatus')
            },
            viewItem() {
                this.$router.push('/offer');
                this.$router.go();
            },
            getData() {
                this.loaded = false;
                let url = '';
                if (localStorage.rol === '1')
                    url = this.base_url + '/admin/offers/' + localStorage.token;
                else if (localStorage.rol === '2')
                    url = this.base_url + '/partner/offers/' + localStorage.token;
                else
                    url = this.base_url + '/company/offers/' + localStorage.token;
                this.axios.get(url).then(response => {
                    this.offers = response.data.offers;
                    this.loaded = true;
                }).catch(()=>{
                  this.loaded = true;
                })
            }
        }
    }
</script>

