<template>
    <v-card class="col-md-12">
        <v-card-title>
            Partners
            <v-icon style="font-size: 12pt !important;color:black !important;margin-left: 0.25em;"
                    v-on:click="refreshData">mdi-reload
            </v-icon>
            <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
                :headers="headers"
                :items="partners"
                :search="search"
                :loading="!loaded"
                loading-text="Loading... Please wait">
            <template v-slot:item.action="{ item }">
                <v-icon color="#000 !important"
                        small
                        @click="deleteItem(item)">
                    mdi-close
                </v-icon>
                <router-link :to="'/dashboard/partner/'+item.pid">
                    <v-icon color="#000 !important" small>
                        mdi-eye
                    </v-icon>
                </router-link>
            </template>
        </v-data-table>
        <create-partner-form :refresh-data="refreshData"/>
    </v-card>
</template>

<script>
    import createPartnerForm from "../../components/Dashboard/Partners/createPartnerForm";

    export default {
        name: "Partners",
        components: {createPartnerForm},
        data() {
            return {

                loaded: false,
                search: '',
                i: '',
                headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        sortable: false,
                        value: 'name',
                    },
                    {text: 'phone', value: 'phone'},
                    {text: 'email', value: 'email'},
                    {text: 'country', value: 'country'},
                    {text: 'Actions', value: 'action', sortable: false},
                ],
                partners: []
            }
        },
        mounted() {
            this.refreshData();
        },
        methods: {
            deleteItem(item) {
              let url = this.base_url + '/admin/removepartner';
              let formData = new FormData();
              formData.set('pid',item.pid)
              formData.set('token', localStorage.token);
              this.axios.post(url, formData).then(() => {
                this.refreshData();
              });
            },
            refreshData() {
                this.loaded = false;
                let url = this.base_url + '/admin/partners/' + localStorage.token;
                this.axios.get(url).then(response => {
                    this.partners = response.data.partners;
                    this.loaded = true;
                })
            }
        }
    }
</script>

<style scoped>

</style>