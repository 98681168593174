<template>
    <v-card class="col-md-12" :loading="!loaded">
        <v-card-title>{{company.name}} - Status: {{company.status}}</v-card-title>
        <v-form v-model="valid" v-if="loaded">
            <v-container>
                <v-row>
                    <info-input md="4" label="Address" :text="company.address"/>
                    <info-input md="4" label="Phone" :text="company.phone"/>
                    <info-input md="4" label="Country" :text="company.country"/>
                    <info-input md="4" label="Web Page" :text="company.webpage"/>
                    <info-input md="4" label="Contact Email" :text="company.contactEmail"/>
                    <info-input md="4" label="Contact Phone" :text="company.contactPhone"/>
                    <info-input md="6" label="Sector" :text="company.sector"/>
                    <info-input md="6" label="Workers" :text="company.nWorkers"/>
                    <info-input md="12" label="Additional Info" :text="company.additionalInfo"/>
                </v-row>
            </v-container>
        </v-form>
        <v-card-title v-if="loaded && offers.length>0">Offers</v-card-title>
        <v-card-title v-else>This company has no job offers published yet</v-card-title>
        <v-simple-table dense v-if="loaded && offers.length>0">
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">Name</th>
                    <th class="text-left">Applies</th>
                    <th class="text-left">Status</th>
                    <th class="text-left">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in offers" :key="item.oid">
                    <td>{{ item.position }}</td>
                    <td>{{ item.applies }}</td>
                    <td>{{ item.status }}</td>
                    <td>
                        <router-link :to="'/dashboard/offer/'+item.oid">
                            <v-icon color="#000 !important"
                                    small>
                                mdi-eye
                            </v-icon>
                        </router-link>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>

<script>
    import infoInput from "../../components/Dashboard/general/infoInput";

    export default {
        name: "Offer",
        components: {infoInput},
        data() {
            return {
                sector: 'IT',
                loaded: false,
                company: [],
                offers: []
            }
        },
        mounted() {
            this.refreshData();
        },
        methods: {
            refreshData() {
                this.loaded = false;
                let url = '';
                if (localStorage.rol === '1')
                    url = this.base_url + '/admin/company/' + this.$route.params.id + '/' + localStorage.token;
                else if (localStorage.rol === '2')
                    url = this.base_url + '/partner/company/' + this.$route.params.id + '/' + localStorage.token;
                else if (localStorage.rol === '3')
                    url = this.base_url + '/company/company/' + this.$route.params.id + '/' + localStorage.token;
                else
                    url = this.base_url + '/student/company/' + this.$route.params.id + '/' + localStorage.token;
                this.axios.get(url).then(response => {
                    this.company = response.data.company;
                    this.offers = response.data.offers;
                    this.loaded = true;
                })
            }
        }
    }
</script>

<style scoped>

</style>