<template>
  <div>
    <v-btn bottom
           color="#95C11F"
           dark
           fab
           right
           style="float: right;top: -5vh;"
           @click="dialog = !dialog">
      <v-icon>add</v-icon>
    </v-btn>
    <v-dialog
        v-model="dialog"
        width="800px">
      <v-card>
        <v-card-title class=" darken-2">
          Create Sector
        </v-card-title>
        <v-container>
          <v-row class="mx-2">
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-domain" placeholder="Sector Name" v-model="sName"/>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-btn class="modalBT" v-on:click="createSector" small color="#213B87">Save</v-btn>
          <v-btn class="modalBT" @click="dialog=false" small color="#9D130B">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "createSectorForm",
  props:['refreshData'],
  data() {
    return {
      dialog: false,
      sName: '',
    }
  },
  methods:{
    createSector(){
      let url = this.base_url + '/admin/newsector';
      let formData = new FormData();
      formData.set('token', localStorage.token);
      formData.set('name', this.sName);
      this.axios.post(url, formData).then(() => {
        this.dialog = false;
        this.refreshData();
      });
    }
  }
}
</script>

<style scoped>

</style>