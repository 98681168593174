<template>
  <v-card
      class="col-md-11"
      color="#213B87"
      dark
      :loading="loaded"
      :shaped=true
      style="margin: 1em">
    <v-card-title>
      <v-icon large
              left>
        file_copy
      </v-icon>
      <span class="title font-weight-bold">Offers pending approval</span>
    </v-card-title>
    <h3 style="text-align: center" v-if="data.length==0">There are no offers to approve</h3>
    <v-simple-table v-else style="background-color: transparent">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">Position</th>
          <th class="text-left">Sector</th>
          <th class="text-left">Company</th>
          <th class="text-left">Country</th>
          <th class="text-left">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in data" :key="item.oid" class="trOffer">
          <td>{{ item.position }}</td>
          <td>{{ item.sector }}</td>
          <td>{{ item.company }}</td>
          <td>{{ item.country }}</td>
          <td>
            <v-icon color="#FFF !important" v-on:click="accept(item)" small v-if="rol!=3">
              mdi-check
            </v-icon>
            <v-icon color="#FFF !important" v-on:click="reject(item)" small v-if="rol!=3">
              mdi-close
            </v-icon>
            <router-link :to="'/dashboard/offer/'+item.oid">
              <v-icon color="#FFF !important" small>
                mdi-eye
              </v-icon>
            </router-link>

          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-card-actions>
      <v-list-item class="grow">
        <v-row align="center"
               justify="end">
                    <span class="subheading mr-2 cardLink"><router-link
                        to="/dashboard/offers">See All</router-link></span>
        </v-row>
      </v-list-item>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "pendingOffersTable",
  props: ['loaded', 'data', 'accept', 'reject'],
  data() {
    return {
      rol: localStorage.rol
    }
  }
}
</script>

<style scoped>
.cardLink a {
  color: #fff !important;
  font-weight: bold;
}

.trOffer:hover {
  background-color: #4a75f1 !important;
}
</style>