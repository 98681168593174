<template>
    <v-col cols="12" :md="md">
        <v-card-subtitle>
            <v-checkbox
                    :value="value"
                    :label="label"
                    :readonly="true"/>
        </v-card-subtitle>
    </v-col>
</template>

<script>
    export default {
        name: "infoCheck",
        props: ['md', 'label', 'value']
    }
</script>

<style scoped>

</style>