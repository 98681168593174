<template>
    <v-card class="col-md-12">
        <form>
            <v-text-field
                    label="Subject"
                    required
            ></v-text-field>
            <v-textarea
                    name="input-7-1"
                    label="Message"
            ></v-textarea>
            <v-btn class="modalBT" small color="#213B87">submit</v-btn>
        </form>
    </v-card>
</template>

<script>
    export default {
        name: "settings"
    }
</script>

<style scoped>

</style>