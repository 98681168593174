<template>
    <v-card class="col-md-12" style="display: contents">
        <modal name="updateFinishedStatus" transition="pop-out" :height="140">
            <v-card class="mx-auto" style="margin: 0 auto;">
                <v-list-item three-line>
                    <v-list-item-content>
                        <v-list-item-title v-if="operation=='acceptCompany'" class="headline mb-1">Do you want to accept {{itemSelected.name}}?</v-list-item-title>
                        <v-list-item-title v-else-if="operation=='acceptOffer'" class="headline mb-1">Do you want to accept {{itemSelected.position}}?</v-list-item-title>
                        <v-list-item-title v-else-if="operation=='declineCompany'" class="headline mb-1">Do you want to decline {{itemSelected.name}}?</v-list-item-title>
                        <v-list-item-title v-else-if="operation=='declineOffer'" class="headline mb-1">Do you want to decline {{itemSelected.position}}?</v-list-item-title>

                    </v-list-item-content>
                </v-list-item>
                <v-card-actions>
                    <v-btn v-if="operation=='acceptCompany'" text color="rgb(33, 59, 135) !important" style="font-size: 12pt !important" v-on:click="acceptCompany(itemSelected)">Accept</v-btn>
                    <v-btn v-else-if="operation=='acceptOffer'" text color="rgb(33, 59, 135) !important" style="font-size: 12pt !important" v-on:click="acceptOffer(itemSelected)">Accept</v-btn>
                    <v-btn v-else-if="operation=='declineCompany'" text color="rgb(33, 59, 135) !important" style="font-size: 12pt !important" v-on:click="rejectCompany(itemSelected)">Accept</v-btn>
                    <v-btn v-else-if="operation=='declineOffer'" text color="rgb(33, 59, 135) !important" style="font-size: 12pt !important" v-on:click="rejectOffer(itemSelected)">Accept</v-btn>
                    <v-btn text color="rgb(33, 59, 135) !important" style="font-size: 12pt !important" @click="$modal.hide('updateFinishedStatus')">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </modal>
        <pending-companies-table :data="companies" :loaded="loaded" :accept="showApproveCompany" :reject="showRejectCompany"/>
        <pending-offers-table :data="offers" :loaded="loaded" :accept="showApproveOffer" :reject="showRejectOffer"/>
    </v-card>
</template>

<script>
    import pendingCompaniesTable from "./partials/pendingCompaniesTable";
    import pendingOffersTable from "./partials/pendingOffersTable";

    export default {
        name: "adminView",
        components: {pendingCompaniesTable, pendingOffersTable},
        data() {
            return {
                loaded: false,
                rol: localStorage.rol,
                companies: [],
                offers: [],
                operation:'',
                itemSelected:''
            }
        },
        mounted() {
            this.refreshData();
        },
        methods: {
            showApproveCompany(item){
                this.itemSelected = item
                this.operation = 'acceptCompany'
                this.$modal.show('updateFinishedStatus')
            },
            showApproveOffer(item){
                this.itemSelected = item
                this.operation = 'acceptOffer'
                this.$modal.show('updateFinishedStatus')
            },
            showRejectCompany(item){
                this.itemSelected = item
                this.operation = 'declineCompany'
                this.$modal.show('updateFinishedStatus')
            },
            showRejectOffer(item){
                this.itemSelected = item
                this.operation = 'declineOffer'
                this.$modal.show('updateFinishedStatus')
            },
            refreshData() {
                this.loaded = false;
                let companiesUrl = this.base_url + '/admin/pendingCompanies/' + localStorage.token;
                this.axios.get(companiesUrl).then(response => {
                    this.companies = response.data.companies;
                }).catch(err=>{
                    if(err.response.status)
                        this.companies = [];
                })
                let offersUrl = this.base_url + '/admin/pendingOffers/' + localStorage.token;
                this.axios.get(offersUrl).then(response => {
                    this.offers = response.data.offers;
                    this.loaded = true;
                }).catch(err=>{
                    if(err.response.status)
                        this.offers = [];
                })
            },
            rejectCompany(item) {
                let url = this.base_url + '/admin/updateCompany';
                let formData = new FormData();
                formData.set('cid', item.cid);
                formData.set('status', 'rejected');
                formData.set('token', localStorage.token);
                this.axios.post(url,formData).then(()=>{
                    this.refreshData();
                    this.$modal.hide('updateFinishedStatus');
                })
            },
            acceptCompany(item) {
                let url = this.base_url + '/admin/updateCompany';
                let formData = new FormData();
                formData.set('cid', item.cid);
                formData.set('status', 'accepted');
                formData.set('token', localStorage.token);
                this.axios.post(url,formData).then(()=>{
                    this.refreshData();
                    this.$modal.hide('updateFinishedStatus');
                })
            },
            rejectOffer(item) {
                let url = this.base_url + '/admin/updateOffer';
                let formData = new FormData();
                formData.set('oid', item.oid);
                formData.set('status', 'rejected');
                formData.set('token', localStorage.token);
                this.axios.post(url,formData).then(()=>{
                    this.refreshData();
                    this.$modal.hide('updateFinishedStatus');
                })
            },
            acceptOffer(item) {
                let url = this.base_url + '/admin/updateOffer';
                let formData = new FormData();
                formData.set('oid', item.oid);
                formData.set('status', 'accepted');
                formData.set('token', localStorage.token);
                this.axios.post(url,formData).then(()=>{
                    this.refreshData();
                    this.$modal.hide('updateFinishedStatus');
                })
            },
        }
    }
</script>

<style scoped>

</style>