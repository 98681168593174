<template>
    <v-card class="col-md-12">
        <v-card-title>
            Offers
            <v-icon style="font-size: 12pt !important;color:black !important;margin-left: 0.25em;">mdi-reload</v-icon>
            <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
                :headers="headers"
                :items="offers"
                :search="search"
                :loading="!loaded"
                loading-text="Loading... Please wait"
                no-data-text="No offers available">
            <template v-slot:item.status="{item}">
                <tr>
                    <td v-if="item.status==='pending'" style="color:#E8AD00; text-transform:uppercase;">
                        {{item.status}}
                    </td>
                    <td v-else-if="item.status==='accepted'" style="color:#217A2B; text-transform:uppercase;">
                        {{item.status}}
                    </td>
                    <td v-else-if="item.status==='denied'" style="color:#9D130B; text-transform:uppercase;">
                        {{item.status}}
                    </td>
                </tr>
            </template>
            <template v-slot:item.action="{ item }">
                <v-icon v-if="item.status !='denied' && item.status != 'accepted' && rol==='1' || rol==='2'"
                        color="#000 !important"
                        small
                        @click="editItem(item)">
                    mdi-check
                </v-icon>
                <v-icon v-if="item.status !='denied' && item.status != 'accepted' && rol==='1' || rol==='2'"
                        color="#000 !important"
                        small
                        @click="deleteItem(item)">
                    mdi-close
                </v-icon>
                <router-link :to="'/dashboard/offer/'+item.oid">
                    <v-icon v-if="item.status !='denied'"
                            color="#000 !important"
                            small>
                        mdi-eye
                    </v-icon>
                </router-link>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    export default {
        name: "adminView",
        data() {
            return {
                loaded: false,
                rol: localStorage.rol,
                offers: [],
                search: '',
                i: '',
                headers: [
                    {
                        text: 'Position',
                        align: 'start',
                        sortable: false,
                        value: 'position',
                    },
                    {text: 'Sector', value: 'sector'},
                    {text: 'Company', value: 'company'},
                    {text: 'Country', value: 'country'},
                    {text: 'Status', value: 'status'},
                    {text: 'Actions', value: 'action', sortable: false},
                ],
            }
        },
        mounted() {
            this.refreshData();
        },
        methods: {
            refreshData() {
                this.loaded = false;
                let url = this.base_url + '/student/getOffers.php?token=' + localStorage.token;
                this.axios.get(url).then(response => {
                    this.offers = response.data.offers;
                    this.loaded = true;
                });
            }
        }
    }
</script>

<style scoped>

</style>