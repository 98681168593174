<template>
    <v-card class="col-md-12">
        <v-card-title>
            <p v-if="rol !== '4'">Offer Detail - Status: {{offer.status}} <v-btn v-if="offer.status != 'closed'" class="modalBT" style="margin-left: 1vw;font-size: 11pt" v-on:click="closeOffer" small color="#213B87">Close Offer</v-btn>
            </p>
            <p v-else>Offer Detail</p>
            <p v-if="rol !== '4'" style="position: absolute;top: 3.5em; font-size: 13pt">Added: {{offer.date_add}} - Viewed:
                {{offer.views}} - Applied:{{appliedNum}}</p>
        </v-card-title>
        <v-form>
            <v-container>
                <v-row>
                    <info-input md="4" label="Position" :text="offer.position"/>
                    <info-input md="4" label="Sector" :text="offer.sector"/>
                    <info-input md="4" label="Country" :text="offer.country"/>
                    <info-input md="4" label="Company" :text="offer.company"/>
                    <info-input md="4" label="Salary" :text="offer.salary"/>
                    <info-check md="2" label="fullTime" :value="fullTime"/>
                    <info-check md="2" label="MidTime" :value="midTime"/>
                    <info-input md="6" label="Offer Requeriments" :text="offer.requeriments"/>
                    <info-input md="6" label="Qualification" :text="offer.qualification"/>
                    <info-input md="6" label="Workplace Conditions" :text="offer.conditions"/>
                    <info-input md="6" label="Rights" :text="offer.rights"/>
                    <info-input md="6" label="Short Description" :text="offer.shortDesc"/>
                    <info-input md="6" label="Long Description" :text="offer.longDesc"/>
                </v-row>
            </v-container>
            <v-card-title v-if="loaded && appliedNum>0">Students who applied this offer</v-card-title>
            <v-card-title v-else-if="loaded && appliedNum==0">No student has requested this offer yet</v-card-title>
            <v-simple-table dense v-if="loaded && appliedNum>0">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">Name</th>
                        <th class="text-left">Email</th>
                        <th class="text-left">Phone</th>
                        <th class="text-left">Status</th>
                        <th class="text-left">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in persons" :key="item.name">
                        <td>{{ item.name}} {{item.lastname}}</td>
                        <td>{{ item.email }}</td>
                        <td>{{ item.phone }}</td>
                        <td>{{ item.status }}</td>
                        <td>
                            <router-link :to="'/dashboard/student/'+item.studentID">
                                <v-icon color="#000 !important" small>
                                    mdi-eye
                                </v-icon>
                            </router-link>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-form>
    </v-card>
</template>

<script>
    import infoInput from "../../components/Dashboard/general/infoInput";
    import infoCheck from "../../components/Dashboard/general/infoCheck";

    export default {
        name: "Offer",
        components: {infoInput, infoCheck},
        data() {
            return {
                loaded: true,
                offer: [],
                appliedNum:0,
                persons:[],
                fullTime:false,
                midTime:false,
                rol: localStorage.rol
            }
        },
        mounted() {
            this.getData();
        },
        methods: {
          closeOffer(){
            let url = '';
            if (localStorage.rol === '1')
              url = this.base_url + '/admin/updateOffer';
            else if (localStorage.rol === '2')
              url = this.base_url + '/partner/updateOffer';
            let formData = new FormData();
            formData.set('oid', this.$route.params.id);
            formData.set('status', 'closed');
            formData.set('token', localStorage.token);
            this.axios.post(url, formData).then(() => {
              this.getData();
            })
          },
            getData() {
                this.loaded = false;
                let url = '';
                if (localStorage.rol === '1')
                    url = this.base_url + '/admin/offer/' + this.$route.params.id + '/' + localStorage.token;
                else if (localStorage.rol === '2')
                    url = this.base_url + '/partner/offer/' + this.$route.params.id + '/' + localStorage.token;
                else if (localStorage.rol === '3')
                    url = this.base_url + '/company/offer/' + this.$route.params.id + '/' + localStorage.token;
                else
                    url = this.base_url + '/student/offer/' + this.$route.params.id + '/' + localStorage.token;
                this.axios.get(url).then(response => {
                    this.offer = response.data.offer;
                    this.appliedNum = response.data.appliedNum;
                    this.persons = response.data.students;
                    if(response.data.offer.fulltime == 1)
                        this.fullTime = true
                    else this.midTime = true;
                    this.loaded = true;
                })
            },
        }
    }
</script>

<style scoped>
</style>