import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Offer from '../views/Offer'
import DashHome from '../views/dashboard/DashHome.vue'
import DashPartners from '../views/dashboard/Partners.vue'
import DashPartner from '../views/dashboard/Partner.vue'
import DashCompanies from '../views/dashboard/Companies'
import DashCompany from '../views/dashboard/Company'
import DashStudents from '../views/dashboard/Students'
import DashStudent from '../views/dashboard/Student'
import DashOffers from '../views/dashboard/Offers'
import DashOffer from '../views/dashboard/Offer'
import Settings from '../views/dashboard/settings'
import Support from '../views/dashboard/support'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/offer/:id',
        name: 'Offer',
        component: Offer
    },
    {
        path: '/dashboard/home',
        name: 'DashHome',
        component: DashHome
    },
    {
        path: '/dashboard/partners',
        name: 'Partners',
        component: DashPartners
    },
    {
        path: '/dashboard/partner/:id',
        name: 'Partner',
        component: DashPartner
    },
    {
        path: '/dashboard/companies',
        name: 'Companies',
        component: DashCompanies
    },
    {
        path: '/dashboard/company/:id',
        name: 'Company',
        component: DashCompany
    },
    {
        path: '/dashboard/students',
        name: 'Students',
        component: DashStudents
    },
    {
        path: '/dashboard/student/:id',
        name: 'Student',
        component: DashStudent
    },
    {
        path: '/dashboard/offers',
        name: 'Offers',
        component: DashOffers
    },
    {
        path: '/dashboard/settings',
        name: 'Settings',
        component: Settings
    },
    {
        path: '/dashboard/support',
        name: 'Support',
        component: Support
    },
    {
        path: '/dashboard/offer/:id',
        name: 'Offer',
        component: DashOffer
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/forgot',
        name: 'Forgot',
        component: () => import('../views/Forgot.vue')
    },
    {
        path: '/reset/:token',
        name: 'RenewPass',
        component: () => import('../views/Renew.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/Register.vue')
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router
