<template>
  <v-container fluid style="display: inline-flex">
    <v-col cols="12" md="6">
      <v-card>
        <v-card-title v-if="loaded">Countries</v-card-title>
        <v-simple-table v-if="loaded">
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">Country</th>
              <th class="text-left">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in countries" :key="item.value">
              <td>{{ item.text}}</td>
              <td>
                <v-icon color="#000 !important" v-on:click="removeCountry(item.value)" small>
                  mdi-close
                </v-icon>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <create-country-form :refresh-data="getData"/>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card>
        <v-card-title v-if="loaded">Sectors</v-card-title>
        <v-simple-table v-if="loaded">
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">Sector</th>
              <th class="text-left">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in sectors" :key="item.value">
              <td>{{ item.text }}</td>
              <td>
                  <v-icon color="#000 !important" v-on:click="removeSector(item.value)" small>
                    mdi-close
                  </v-icon>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <create-sector-form :refresh-data="getData"/>
      </v-card>
    </v-col>

  </v-container>
</template>

<script>
import CreateCountryForm from "@/components/settings/createCountryForm";
import CreateSectorForm from "@/components/settings/createSectorForm";
export default {
  name: "settings",
  components: {CreateSectorForm, CreateCountryForm},
  data() {
    return {
      loaded: true,
      countries: [],
      sectors: [],
    }
  },
  mounted() {
    this.getData()
  },
  methods:{
    removeCountry(cid){
      let url = this.base_url + '/admin/removecountry';
      let formData = new FormData();
      formData.set('token', localStorage.token);
      formData.set('cid', cid);
      this.axios.post(url, formData).then(() => {
        this.dialog = false;
        this.getData();
      });
    },
    removeSector(sid){
      let url = this.base_url + '/admin/removesector';
      let formData = new FormData();
      formData.set('token', localStorage.token);
      formData.set('sid', sid);
      this.axios.post(url, formData).then(() => {
        this.dialog = false;
        this.getData();
      });
    },
    getData() {
      let url1 = this.base_url + '/home/sectors';
      let url2 = this.base_url + '/home/countries';
      this.axios.get(url1).then(response => {
        this.sectors = response.data.sectors;
      })
      this.axios.get(url2).then(response => {
        this.countries = response.data.countries;
      })
    }
  }
}
</script>

<style scoped>

</style>