<template>
  <v-card
      class="mx-auto"
      max-width="300"
      height="420"
      style="margin: 1em">
    <v-img class="white--text align-end" height="200px" :src="imgsrc">
      <v-card-title style=" word-wrap: break-word;word-break: break-word;">
        {{ offer.position }}
      </v-card-title>
    </v-img>
    <v-card-subtitle class="pb-0">{{ offer.sector }}</v-card-subtitle>
    <v-card-text style="height: 130px !important;" class="text--primary">
      <div>{{offer.shortDesc | truncate(105, '...')}}</div>
      <div class="location">
        <v-icon left>mdi-map-marker</v-icon>
        {{ offer.city }}, {{ offer.country }}
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
          style="color:rgb(33, 59, 135) !important;font-size: 10pt !important;"
          text
          v-on:click="seeOffer(offer.oid)">
        Read More
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "offerCard",
  props: ['offer'],
  data() {
    return {
      imgsrc: ''
    }
  },
  created() {
    this.getImage(this.offer.sid)
  },
  methods: {
    seeOffer(oid) {
      this.logged = localStorage.token != null;
      if (!this.logged) this.$router.push('/login');
      else this.$router.push('/offer/' + oid);
    },
    getImage(sid) {
      const path = '/assets/images/sectors/';
      switch (parseInt(sid)) {
        case 1:
          this.imgsrc = path + 'Erovet-jobpool-sectors-automation.jpg'
          break;
        case 2:
          this.imgsrc = path + 'Erovet-jobpool-sectors-Business-and-administration.jpg'
          break;
        case 3:
          this.imgsrc = path + 'Erovet-jobpool-sectors-Carpentry.jpg'
          break;
        case 4:
          this.imgsrc = path + 'Erovet-jobpool-sectors-Catering-and-restaurant-services.jpg'
          break;
        case 5:
          this.imgsrc = path + 'Erovet-jobpool-sectors-Chemistry.jpg'
          break;
        case 6:
          this.imgsrc = path + 'Erovet-jobpool-sectors-Construction.jpg'
          break;
        case 7:
          this.imgsrc = path + 'Erovet-jobpool-sectors-Electricity-and-electronics.jpg'
          break;
        case 8:
          this.imgsrc = path + 'Erovet-jobpool-sectors-Energy-and-water.jpg'
          break;
        case 9:
          this.imgsrc = path + 'Erovet-jobpool-sectors-Enviroment.jpg'
          break;
        case 10:
          this.imgsrc = path + 'Erovet-jobpool-sectors-Hairdressing-and-personal-image.jpg'
          break;
        case 11:
          this.imgsrc = path + 'Erovet-jobpool-sectors-Health-and-welfare.jpg'
          break;
        case 12:
          this.imgsrc = path + 'Erovet-jobpool-sectors-Heating-and-cooling.jpg'
          break;
        case 13:
          this.imgsrc = path + 'Erovet-jobpool-sectors-Hotel-and-tourism.jpg'
          break;
        case 14:
          this.imgsrc = path + 'Erovet-jobpool-sectors-Comunications-technology.jpg'
          break;
        case 15:
          this.imgsrc = path + 'Erovet-jobpool-sectors-Mechanics.jpg'
          break;
        case 16:
          this.imgsrc = path + 'Erovet-jobpool-sectors-Media.jpg'
          break;
        case 17:
          this.imgsrc = path + 'Erovet-jobpool-sectors-Metallurgy.jpg'
          break;
        case 18:
          this.imgsrc = path + 'Erovet-jobpool-sectors-security-guard.jpg'
          break;
        case 19:
          this.imgsrc = path + 'Erovet-jobpool-sectors-Social-Services.jpg'
          break;
        case 20:
          this.imgsrc = path + 'Erovet-jobpool-sectors-automation.jpg'
          break;
        case 21:
          this.imgsrc = path + 'Erovet-jobpool-sectors-Trade-and-ecommerce.jpg'
          break;
        case 22:
          this.imgsrc = path + 'Erovet-jobpool-sectors-Other.jpg'
          break;
        case 23:
          this.imgsrc = path + 'Erovet-jobpool-sectors-Transport.jpg'
      }
    }
  }
}
</script>

<style scoped>
.v-card__title {
  background: rgba(33, 59, 135, 0.7) !important;
}
.location{
  position: absolute;
  bottom: 60px;
}
</style>