 <template>
    <v-card class="col-md-12" style="background-color: transparent; box-shadow:none;">
        <v-card-title class="filterHead">
            <v-row dense>
                <v-col cols="5">
                    <v-text-field
                            v-model="searchFilter"
                            append-icon="mdi-magnify"
                            label="Search Offer"
                            single-line
                            hide-details/>
                </v-col>
                <v-col cols="3">
                    <v-combobox
                            v-model="sectorsSelect"
                            :items="sectors"
                            label="Filter by sector"
                            multiple/>
                </v-col>
                <v-col cols="3">
                    <v-combobox
                            v-model="countriesSelect"
                            :items="countries"
                            label="Filter by country"
                            multiple/>
                </v-col>
                <v-col cols="1" style="padding: 1em;">
                    <v-icon right class="iconView" v-bind:class="{'active':selected==='grid'}"
                            v-on:click="selectGridView"> mdi-grid
                    </v-icon>
                    <v-icon right class="iconView" v-bind:class="{'active':selected==='list'}"
                            v-on:click="selectListView"> mdi-menu
                    </v-icon>
                </v-col>
            </v-row>
        </v-card-title>
        <v-container fluid>
            <v-row dense v-if="selected==='grid'">
                <card v-for="offer in filteredOffers" :key="offer.index" :offer="offer"/>
            </v-row>
            <v-data-table
                    v-else
                    :headers="headers"
                    :items="filteredOffers">
                <template v-slot:item.action="{ item }">
                    <v-icon v-on:click="seeOffer(item)"
                            color="#000 !important"
                            small
                            class="iconViewOffer">
                        mdi-eye
                    </v-icon>
                </template>
            </v-data-table>
        </v-container>
    </v-card>
</template>
<script>
    import card from "../components/Home/offerCard";

    export default {
        name: 'Home',
        components: {card},
        data() {
            return {
                selected: 'grid',
                searchFilter: '',
                enabled: null,
                sectorsSelect: ["All"],
                countriesSelect: ["All"],
                   headers: [
                    {
                        text: 'Position',
                        align: 'start',
                        sortable: false,
                        value: 'position',
                    },
                    {text: 'Sector', value: 'sector'},
                    {text: 'Short Desc.', value: 'shortDesc'},
                    {text: 'Country', value: 'country'},
                    {text: 'Actions', value: 'action', sortable: false},
                ],
                offers: [],
              sectors:[],
              countries:[]
            }
        },
        mounted() {
            this.getOffers();
        },
        methods: {
            selectGridView() {
                this.selected = 'grid';
            },
            selectListView() {
                this.selected = 'list';
            },
            getOffers() {
                this.loaded = false;
                let url = this.base_url + '/home/offers/';
                this.axios.get(url).then(response => {
                    this.offers = response.data.offers;
                    this.loaded = true;
                })
              let url1 = this.base_url + '/home/sectors';
              let url2 = this.base_url + '/home/countries';
              this.axios.get(url1).then(response => {
                this.sectors = response.data.sectors;
              })
              this.axios.get(url2).then(response => {
                this.countries = response.data.countries;
              })
            },
            seeOffer(item) {
                this.logged = localStorage.token != null;
                if (!this.logged) this.$router.push('/login');
                else this.$router.push('/offer/' + item.oid);
            }
        },
        watch: {
            sectorsSelect: function (val) {
                if (val.length === 0) val = ["All"];
                else {
                    if (val.length >= 2) {
                        if (val[val.length - 1] === "All")
                            val = ["All"];
                        else {
                            const index = val.indexOf("All");
                            if (index > -1) {
                                val.splice(index, 1);
                            }
                        }
                    }
                }
                this.sectorsSelect = val;
            },
            countriesSelect: function (val) {
                if (val.length === 0) val = ["All"];
                else {
                    if (val.length >= 2) {
                        if (val[val.length - 1] === "All")
                            val = ["All"];
                        else {
                            const index = val.indexOf("All");
                            if (index > -1) {
                                val.splice(index, 1);
                            }
                        }
                    }
                }
                this.countriesSelect = val;
            }
        },
        computed: {
            filteredOffers() {
                const search = this.searchFilter.toLowerCase().trim();
                const countries = this.countriesSelect;
                const sectors = this.sectorsSelect;
                if (!search && countries.length === 1 && countries[0] === "All" && sectors.length === 1 && sectors[0] === "All") {
                    return this.offers;
                } else {
                    let copyOffers = this.offers;
                    if (countries[0] !== "All") {
                        let countfilters = [];
                        for (let countryItem in countries) {
                            let countryOffers = copyOffers.filter(c => c.country.toLowerCase().indexOf(countries[countryItem]['text'].toLowerCase()) > -1);
                            countryOffers.forEach(ofit => {
                                countfilters.push(ofit);
                            });
                        }
                        copyOffers = countfilters;
                    }

                    if (sectors[0] !== "All") {
                        let secfilters = [];
                        for (let sectorItem in sectors) {
                            let sectorOffers = copyOffers.filter(c => c.sector.toLowerCase().indexOf(sectors[sectorItem]['text'].toLowerCase()) > -1);
                            sectorOffers.forEach(secit => {
                                secfilters.push(secit);
                            });
                        }
                        copyOffers = secfilters;
                    }
                    let searchfilters = [];
                    let searchOffers = copyOffers.filter(c => c.position.toLowerCase().indexOf(search) > -1);
                    searchOffers.forEach(seacit => {
                        searchfilters.push(seacit);
                    });
                    copyOffers = searchfilters;
                    return copyOffers;
                }
            }
        }
    }
</script>
<style>
    .filterHead {
        background-color: rgba(255, 255, 255, 0.85);
        -webkit-box-shadow: 10px 9px 12px -8px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 10px 9px 12px -8px rgba(0, 0, 0, 0.75);
        box-shadow: 10px 9px 12px -8px rgba(0, 0, 0, 0.75);
    }

    .iconView {
        color: lightgrey !important;
    }

    .iconView.active {
        color: rgb(33, 59, 135) !important;
    }

    .mdi-eye:hover {
        color: rgb(33, 59, 135) !important;
    }

</style>
