<template>
    <v-card
            class="col-md-11"
            color="#95C11F"
            dark
            :loading="loaded"
            :shaped=true
            style="margin: 1em">
        <v-card-title>
            <v-icon large
                    left>domain
            </v-icon>
            <span class="title font-weight-bold">Companies pending approval</span>
        </v-card-title>
        <h3 style="text-align: center" v-if="data.length==0">There are no companies to approve</h3>
        <v-simple-table v-else style="background-color: transparent">
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">Name</th>
                    <th class="text-left">Sector</th>
                    <th class="text-left">Phone</th>
                    <th class="text-left">Country</th>
                    <th class="text-left">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in data" :key="item.name" class="trCompany">
                    <td>{{ item.name }}</td>
                    <td>{{ item.sector }}</td>
                    <td>{{ item.phone }}</td>
                    <td>{{ item.country }}</td>
                    <td>
                            <v-icon color="#FFF !important" v-on:click="accept(item)" small>
                                mdi-check
                            </v-icon>
                            <v-icon color="#FFF !important" v-on:click="reject(item)" small>
                                mdi-close
                            </v-icon>
                        <router-link :to="'/dashboard/company/'+item.cid">
                        <v-icon color="#FFF !important" small>
                                mdi-eye
                            </v-icon>
                        </router-link>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-card-actions>
            <v-list-item class="grow">
                <v-row align="center"
                       justify="end">
                    <span class="subheading mr-2 cardLink"><router-link to="/dashboard/companies">See All</router-link></span>
                </v-row>
            </v-list-item>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "pendingCompaniesTable",
        props: ['loaded', 'data', 'accept','reject']
    }
</script>

<style scoped>
    .cardLink a {
        color: #fff !important;
        font-weight: bold;
    }

    .trCompany:hover {
        background-color: #217A2B !important;
    }
</style>