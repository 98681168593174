<template>
  <div>
    <v-btn bottom
           color="#95C11F"
           dark
           fab
           right
           style="float: right;top: -5vh;"
           @click="dialog = !dialog">
      <v-icon>add</v-icon>
    </v-btn>
    <v-dialog
        v-model="dialog"
        width="800px">
      <v-card>
        <v-card-title class=" darken-2">
          Create Country
        </v-card-title>
        <v-container>
          <v-row class="mx-2">
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-domain" placeholder="Country Name" v-model="name"/>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-btn class="modalBT" v-on:click="createCountry" small color="#213B87">Save</v-btn>
          <v-btn class="modalBT" @click="dialog=false" small color="#9D130B">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
name: "createCountryForm",
  props:['refreshData'],
  data() {
    return {
      dialog: false,
      name: '',
    }
  },
  methods:{
    createCountry(){
      let url = this.base_url + '/admin/newcountry';
      let formData = new FormData();
      formData.set('token', localStorage.token);
      formData.set('name', this.name);
      this.axios.post(url, formData).then(() => {
        this.dialog = false;
        this.refreshData();
      });
    }
  }
}
</script>

<style scoped>

</style>