<template>
    <v-card class="col-md-12">
        <v-card-title>
            Students
            <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table item-key="name" class="elevation-1" loading loading-text="Loading... Please wait"></v-data-table>
    </v-card>
</template>

<script>
    export default {
        name: "Students"
    }
</script>

<style scoped>

</style>