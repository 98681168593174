<template>
  <div>
    <v-btn bottom
           color="#95C11F"
           dark
           fab
           fixed
           right
           @click="dialog = !dialog">
      <v-icon>add</v-icon>
    </v-btn>
    <v-dialog
        v-model="dialog"
        width="800px">
      <v-card>
        <v-card-title class=" darken-2">
          Create Offer
        </v-card-title>
        <v-container>
          <v-row class="mx-2">
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-account-card-details-outline"
                            placeholder="Position" v-model="offer.position"/>
            </v-col>
            <v-col cols="6">
              <v-select
                  prepend-icon="mdi-server"
                  :items="sectors"
                  label="Sector"
                  v-model="offer.sector"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-city"
                            placeholder="City/Town" v-model="offer.location"/>
            </v-col>
            <v-col cols="6">
              <v-select
                  prepend-icon="mdi-flag"
                  :items="countries"
                  label="Country"
                  v-model="offer.country"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                  prepend-icon="mdi-flag"
                  :items="companies"
                  label="Company"
                  v-model="offer.company"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field prepend-icon="mdi-currency-eur" placeholder="Salary" v-model="offer.salary"/>
            </v-col>
            <v-col cols="6">
              <v-checkbox
                  value="1"
                  :label="`Fulltime`"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="6">
              <v-textarea
                  name="input-7-1"
                  label="Offer Requeriments"
                  v-model="offer.offerRequeriments"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="6">
              <v-textarea
                  name="input-7-1"
                  label="Qualification"
                  v-model="offer.qualification"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="6">
              <v-textarea
                  name="input-7-1"
                  label="Workplace Conditions"
                  v-model="offer.conditions"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="6">
              <v-textarea
                  name="input-7-1"
                  label="Rights"
                  v-model="offer.rights"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea
                  name="input-7-1"
                  label="Short Description"
                  v-model="offer.short"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea
                  name="input-7-1"
                  label="Long Description"
                  v-model="offer.long"
              ></v-textarea>
            </v-col>
          </v-row>
          <empty-form :alert="alert"/>
        </v-container>
        <v-card-actions>
          <v-btn class="modalBT" v-on:click="createOffer" small color="#213B87">Save</v-btn>
          <v-btn class="modalBT" @click="dialog = false" small color="#9D130B">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EmptyForm from "../Alerts/emptyForm";
export default {
  name: "createOfferForm",
  components: {EmptyForm},
  props:['refreshData'],
  data() {
    return {
      dialog: false,
      alert:false,
      offer:[],
      countries: [],
      sectors: [],
      companies: []
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    createOffer() {
      if(this.offer.position === undefined || this.offer.qualification === undefined || this.offer.location === undefined || this.offer.short === undefined || this.offer.long === undefined || this.offer.offerRequeriments === undefined || this.offer.conditions === undefined || this.offer.salary === undefined || this.offer.rights===undefined){
        this.alert=true;
      }else{
        let url = '';
        if (localStorage.rol === '1')
          url = this.base_url + '/admin/newoffer';
        else if(localStorage.rol === '2')
          url = this.base_url + '/partner/newoffer';
        else if(localStorage.rol === '3')
          url = this.base_url + '/company/newoffer';
        let formData = new FormData();
        formData.set('cid', this.offer.company);
        formData.set('position', this.offer.position);
        formData.set('qualification', this.offer.qualification);
        formData.set('location', this.offer.location);
        formData.set('shortDesc', this.offer.short);
        formData.set('longDesc', this.offer.long);
        formData.set('requeriments', this.offer.offerRequeriments);
        formData.set('qualification', this.offer.qualification);
        formData.set('conditions', this.offer.conditions);
        formData.set('salary', this.offer.salary);
        formData.set('rights', this.offer.rights);
        formData.set('sid', this.offer.sector);
        formData.set('coid',this.offer.country);
        formData.set('token', localStorage.token);
        this.axios.post(url, formData).then(() => {
          this.dialog = false;
          this.offer = [];
          this.refreshData;
        });
      }
    },
    getData() {
      let url1 = this.base_url + '/home/sectors';
      let url2 = this.base_url + '/home/countries';
      let url3 = '';
      if (localStorage.rol === '1')
         url3 = this.base_url + '/admin/companiesList/' + localStorage.token;
      else if (localStorage.rol === '2')
         url3 = this.base_url + '/partner/companiesList/' + localStorage.token;
      else if (localStorage.rol === '3')
        url3 = this.base_url + '/company/companiesList/' + localStorage.token;
      this.axios.get(url1).then(response => {
        this.sectors = response.data.sectors;
      })
      this.axios.get(url2).then(response => {
        this.countries = response.data.countries;
      })
      this.axios.get(url3).then(response => {
        this.companies = response.data.companies;
        if(localStorage.rol === '3')
          this.company = response.data.companies[0].value
      })
    }
  }
}
</script>

<style scoped>

</style>