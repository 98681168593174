<template>
  <v-card class="col-md-12">
    <v-card-title>
      <p>Student Info</p>
    </v-card-title>
    <v-form>
      <v-container>
        <v-row>
          <info-input md="4" label="Name" :text="student.name"/>
          <info-input md="4" label="Lastname" :text="student.lastname"/>
          <v-col cols="12" :md="4">
            <v-card-title style="color:#213B87; font-size: 12pt">
              Linkedin Profile
            </v-card-title>
            <v-card-subtitle>
              <a v-if="student.inPath != ''" :href="student.inPath" rel="nofollow" target="_blank">
                     <v-icon color="gray darken-2" class="headRRSS" style="font-size: 12pt;color: rgb(33, 59, 135);top: -0.4vh;">mdi-linkedin</v-icon>
                {{ student.inPath }}

              </a>
            </v-card-subtitle>
          </v-col>
          <info-input md="6" label="Email" :text="student.email"/>
          <info-input md="6" label="Phone" :text="student.phone"/>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import infoInput from "../../components/Dashboard/general/infoInput";

export default {
  name: "Student",
  components: {infoInput},
  data() {
    return {
      loaded: true,
      student: [],
      rol: localStorage.rol
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loaded = false;
      let url = '';
      if (localStorage.rol === '1')
        url = this.base_url + '/admin/student/' + this.$route.params.id + '/' + localStorage.token;
      else if (localStorage.rol === '2')
        url = this.base_url + '/partner/offer/' + this.$route.params.id + '/' + localStorage.token;
      else if (localStorage.rol === '3')
        url = this.base_url + '/company/offer/' + this.$route.params.id + '/' + localStorage.token;
      else
        url = this.base_url + '/student/offer/' + this.$route.params.id + '/' + localStorage.token;
      this.axios.get(url).then(response => {
        this.student = response.data.student

        this.loaded = true;
      })
    },
  }
}
</script>

<style scoped>
</style>