<template>
    <v-card class="col-md-12">
        <modal name="updateFinishedStatus" transition="pop-out" :height="140">
            <v-card class="mx-auto" style="margin: 0 auto;">
                <v-list-item three-line>
                    <v-list-item-content>
                        <v-list-item-title class="headline mb-1">Do you want to {{operation}} {{itemSelected.name}}?
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-card-actions>
                    <v-btn v-if="operation=='accept'" text color="rgb(33, 59, 135) !important"
                           style="font-size: 12pt !important" v-on:click="acceptCompany(itemSelected)">Accept
                    </v-btn>
                    <v-btn v-else text color="rgb(33, 59, 135) !important" style="font-size: 12pt !important"
                           v-on:click="rejectCompany(itemSelected)">Accept
                    </v-btn>
                    <v-btn text color="rgb(33, 59, 135) !important" style="font-size: 12pt !important"
                           @click="$modal.hide('updateFinishedStatus')">Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </modal>
        <v-card-title>
            Companies
            <v-icon style="font-size: 12pt !important;color:black !important;margin-left: 0.25em;"
                    v-on:click="refreshData">mdi-reload
            </v-icon>
            <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details/>
        </v-card-title>
        <v-data-table
                :headers="headers"
                :items="companies"
                :search="search"
                :loading="!loaded"
                loading-text="Loading... Please wait">
            <template v-slot:item.status="{item}">
                <tr>
                    <td v-if="item.status==='pending'" style="color:#E8AD00; text-transform:uppercase;">
                        {{item.status}}
                    </td>
                    <td v-else-if="item.status==='accepted'" style="color:#217A2B; text-transform:uppercase;">
                        {{item.status}}
                    </td>
                    <td v-else-if="item.status==='rejected'" style="color:#9D130B; text-transform:uppercase;">
                        {{item.status}}
                    </td>
                </tr>
            </template>
            <template v-slot:item.action="{ item }">
                <v-icon v-if="item.status !='rejected' && item.status != 'accepted'"
                        color="#000 !important"
                        small
                        class="mr-2 acceptIcon"
                        @click="showApproveDialog(item)">
                    mdi-check
                </v-icon>
                <v-icon v-if="item.status !='rejected' && item.status != 'accepted'"
                        color="#000 !important"
                        small
                        class="mr-2 deniedIcon"
                        @click="showRejectDialog(item)">
                    mdi-close
                </v-icon>
              <v-icon v-else
                      color="#000 !important"
                      small
                      class="mr-2 deniedIcon"
                      @click="deleteItem(item)">
                mdi-close
              </v-icon>
                <router-link :to="'/dashboard/company/'+item.cid">
                    <v-icon color="#000 !important"
                            small class="seeIcon">
                        mdi-eye
                    </v-icon>
                </router-link>
            </template>
        </v-data-table>
        <create-company-form :refresh-data="refreshData"/>
    </v-card>
</template>

<script>
    import createCompanyForm from "../../components/Dashboard/Companies/createCompanyForm";

    export default {
        name: "Companies",
        components: {createCompanyForm},
        data() {
            return {
                dialog: false,
                loaded: false,
                search: '',
                i: '',
                companies: [],
                headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        sortable: false,
                        value: 'name',
                    },
                    {text: 'address', value: 'address'},
                    {text: 'country', value: 'country'},
                    {text: 'phone', value: 'phone'},
                    {text: 'sector', value: 'sector'},
                    {text: 'status', value: 'status'},
                    {text: 'offers', value: 'offers'},
                    {text: 'Actions', value: 'action', sortable: false},
                ],
                itemSelected: '',
                operation: ''
            }
        },
        methods: {
          deleteItem(item) {
            let url = '';
            if (localStorage.rol === '1') url = this.base_url + '/admin/removecompany';
            else  url = this.base_url+'/partner/removecompany';
            let formData = new FormData();
            formData.set('cid',item.cid)
            formData.set('token', localStorage.token);
            this.axios.post(url, formData).then(() => {
              this.refreshData();
            });
          },
            rejectCompany(item) {
                let url = '';
                if (localStorage.rol === '1')
                    url = this.base_url + '/admin/updateCompany';
                else if (localStorage.rol === '2')
                    url = this.base_url + '/partner/updateCompany';
                let formData = new FormData();
                formData.set('cid', item.cid);
                formData.set('status', 'rejected');
                formData.set('token', localStorage.token);
                this.axios.post(url, formData).then(() => {
                    this.refreshData();
                    this.$modal.hide('updateFinishedStatus');
                })
            },
            acceptCompany(item) {
                let url = '';
                if (localStorage.rol === '1')
                    url = this.base_url + '/admin/updateCompany';
                else if (localStorage.rol === '2')
                    url = this.base_url + '/partner/updateCompany';
                let formData = new FormData();
                formData.set('cid', item.cid);
                formData.set('status', 'accepted');
                formData.set('token', localStorage.token);
                this.axios.post(url, formData).then(() => {
                    this.refreshData();
                    this.$modal.hide('updateFinishedStatus');
                })
            },
            showApproveDialog(item) {
                this.itemSelected = item
                this.operation = 'accept'
                this.$modal.show('updateFinishedStatus')
            },
            showRejectDialog(item) {
                this.itemSelected = item
                this.operation = 'decline'
                this.$modal.show('updateFinishedStatus')
            },
            viewItem() {
                this.$router.push('/company');
                this.$router.go();
            },
            refreshData() {
                this.loaded = false;
                let url = '';
                if (localStorage.rol === '1')
                    url = this.base_url + '/admin/companies/' + localStorage.token;
                else
                    url = this.base_url + '/partner/companies/' + localStorage.token;

                this.axios.get(url).then(response => {
                    this.companies = response.data.companies;
                    this.loaded = true;
                }).catch(()=>{
                  this.loaded = true;
                })
            }
        },
        mounted() {
            this.refreshData();
        }
    }
</script>
