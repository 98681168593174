import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vuetify from './plugins/vuetify';
import VueGlobalVariable from "vue-global-var";
import VModal from 'vue-js-modal';

Vue.use(VueAxios, axios);
Vue.config.productionTip = false;
Vue.use(VueGlobalVariable, {
    globals: {
        base_url: 'https://api.erovet.eu'
    }
});
Vue.use(VModal, { dialog: true })
let filter = function(text, length, clamp){
    clamp = clamp || '...';
    let node = document.createElement('div');
    node.innerHTML = text;
    let content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
