<template>
    <v-card class="col-md-12" style="display: contents">
        <pending-offers-table :data="offers" :loaded="loaded"/>
    </v-card>
</template>

<script>
    import pendingOffersTable from "./partials/pendingOffersTable";

    export default {
        name: "adminView",
        components: {pendingOffersTable},
        data() {
            return {
                loaded: false,
                rol: localStorage.rol,
                companies: [],
                offers: []
            }
        },
        mounted() {
            this.refreshData();
        },
        methods: {
            refreshData() {
                this.loaded = false;
                let url = this.base_url + '/company/pendingOffers/' + localStorage.token;
                this.axios.get(url).then(response => {
                    this.offers = response.data.offers;
                    this.loaded = true;
                })
            }
        }
    }
</script>

<style scoped>

</style>